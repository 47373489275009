import React from 'react';
import LateralMenu from '../LateralMenu';
import { faVideo, faClipboardList, faUser } from '@fortawesome/free-solid-svg-icons';

import { translate } from '../../services/translate';
import logo from '../../../assets/logo_dashboard.png';
import collapsedLogo from '../../../assets/dashboard_icon.png';

export default function DashboardLateralMenu({ navigation, open, authenticatedUser, setOpen, selectedMenu }: LateralMenuProps) {

    const items = [];

    if (authenticatedUser?.isAdmin
        || authenticatedUser?.permissions.dashboard_module_access
        || authenticatedUser?.permissions.dashboard_see_occurrence
    ) {
        items.push({
            icon: faClipboardList,
            text: translate('occurrences'),
            action: () => navigation.navigate('Dashboard', { screen: 'DashboardOccurrences' }),
            selected: selectedMenu == 'occurrences'
        });
    }

    if (authenticatedUser?.isAdmin
        || authenticatedUser?.permissions.dashboard_module_access
        || authenticatedUser?.permissions.dashboard_see_cameras
    ) {
        items.push({
            icon: faVideo,
            text: translate('cameras'),
            action: () => navigation.navigate('Dashboard', { screen: 'DashboardCameras' }),
            selected: selectedMenu == 'cameras'
        });
    }

    if (authenticatedUser?.isAdmin
        || authenticatedUser?.permissions.dashboard_module_access
        || authenticatedUser?.permissions.dashboard_see_agents
        || authenticatedUser?.permissions.dashboard_see_agents_acting_bodies
        || authenticatedUser?.permissions.dashboard_see_agents_acting_body_units
    ) {
        items.push({
            icon: faUser,
            text: translate('agents'),
            action: () => navigation.navigate('Dashboard', { screen: 'DashboardAgents' }),
            selected: selectedMenu == 'agents'
        });
    }

    return (
        <LateralMenu
            topImage={logo}
            collapsedTopImage={collapsedLogo}
            open={open}
            setOpen={setOpen}
            authenticatedUser={authenticatedUser}
            items={items}
        />
    );
}
