import React, { useEffect, useState } from 'react';
import FilterDropdown from '../Filter/components/FilterDropdown';
import { translate } from '../../services/translate';
import { licensePlateRecognitionService } from '../../services/central-api/license-plate-recognition';

interface Props {
    value: string;
    setValue: React.Dispatch<React.SetStateAction<string>>;
    zIndex: number;
}

export default function BrandsDropdown({ value, setValue, zIndex }: Props) {
    const [items, setItems] = useState<{ label: string, value: string; }[]>([]);

    async function getItems() {
        try {
            const items = await licensePlateRecognitionService.getBrands();
            setItems(items.map((item) => {
                return {
                    value: item.brand,
                    label: item.brand
                };
            }));
        } catch (err) {
            console.error(err);
        }
    }

    useEffect(() => {
        getItems();
    }, []);

    return (
        <FilterDropdown
            label={translate('brand')}
            items={items}
            multiple={false}
            setValue={setValue}
            value={value}
            zIndex={zIndex}
        />
    );
}
