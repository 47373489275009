import React, { useState } from 'react';
import { Modal, View } from 'react-native';
import { translate } from '../../../services/translate';
import FormInput from '../../../components/formInput';
import FormActions from '../../../components/formActions';
import { WindowInformation } from '../../../services/window-information';
import { createStyleSheet, useStyles } from 'react-native-unistyles';
import Toast from 'react-native-toast-message';
import moment from 'moment';
import MyAppText from '../../../components/MyAppText';
import { licensePlateRecognitionService } from '../../../services/central-api/license-plate-recognition';
import MyDatetimeInput from '../../../components/MyDatetimeInput';
import { ScrollView } from 'react-native-web-hover';
import FilterInputNumeric from '../../../components/Filter/components/FilterInputNumeric';
import CamerasDropdown from '../../../components/lpr/CamerasDropdown';
import BrandsDropdown from '../../../components/lpr/BrandsDropdown';
import ModelsDropdown from '../../../components/lpr/ModelDropdown';
import ColorsDropdown from '../../../components/lpr/ColorDropdown';

interface ModalProps {
    isModalVisible: boolean;
    setModalVisible: (value: boolean) => void;
    onClose: () => void;
}

const regex = /^[a-zA-Z0-9 +\-./:=_ãõẽíáàâêóôúüçÃÕẼÍÁÀÂÊÓÔÚÜÇ]*$/;

export default function ReportModal({ onClose, isModalVisible, setModalVisible }: ModalProps) {
    const { styles, theme } = useStyles(styleSheet);
    const windowInfo = WindowInformation();

    const [name, setName] = useState<string>('');
    const [plate, setPlate] = useState<string>('');
    const [brand, setBrand] = useState<string>('');
    const [model, setModel] = useState<string>('');
    const [color, setColor] = useState<string>('');
    const [year, setYear] = useState<number | null>(null);
    const [startInDateFilter, setStartInDateFilter] = useState(moment().subtract(1, 'hour').format('DD/MM/YYYY'));
    const [startInHourFilter, setStartInHourFilter] = useState(moment().subtract(1, 'hour').format('HH:mm:ss'));
    const [endInDateFilter, setEndInDateFilter] = useState(moment().format('DD/MM/YYYY'));
    const [endInHourFilter, setEndInHourFilter] = useState(moment().format('HH:mm:ss'));
    const [selectedCameraFilter, setSelectedCameraFilter] = useState<string[]>([]);

    function isNameInvalid(value: string) {
        return value == '' || !regex.test(value);
    }

    function isFormValid() {
        if (isNameInvalid(name)) {
            return false;
        } else if (moment(`${startInDateFilter} ${startInHourFilter}`, 'DD/MM/YYYY HH:mm:ss').valueOf() >= moment(`${endInDateFilter} ${endInHourFilter}`, 'DD/MM/YYYY HH:mm:ss').valueOf()) {
            return false;
        } else if (selectedCameraFilter.length == 0) {
            return false;
        } else if (year !== null && (year > moment().year() || year < 1900)) {
            return false;
        }

        return true;
    }

    function onCloseNewReportModal() {
        setModalVisible(false);
        resetReportForm();
    }

    async function formSubmit() {
        try {
            await licensePlateRecognitionService.createReport({
                name: name,
                startDate: moment(`${startInDateFilter} ${startInHourFilter}`, 'DD/MM/YYYY HH:mm:ss').toISOString(),
                endDate: moment(`${endInDateFilter} ${endInHourFilter}`, 'DD/MM/YYYY HH:mm:ss').toISOString(),
                plate: plate,
                brand: brand !== '' ? brand : undefined,
                model: model !== '' ? model : undefined,
                color: color !== '' ? color : undefined,
                year: year !== null ? year : undefined,
                camerasIds: selectedCameraFilter
            });
            Toast.show({
                type: 'sentinelxSuccess',
                text1: translate('ActionSuccessfully'),
            });
        } catch (error) {
            Toast.show({
                type: 'sentinelxError',
                text1: translate('unexpectedError'),
            });
        } finally {
            onCloseNewReportModal();
            onClose();
        }
    }

    function resetReportForm() {
        setName('');
        setSelectedCameraFilter([]);
        setPlate('');
        setBrand('');
        setModel('');
        setColor('');
        setYear(null);
    }

    return (
        <Modal transparent={true} visible={isModalVisible} animationType='fade' onRequestClose={() => onCloseNewReportModal()} >
            <View style={styles.container}>
                <View style={[styles.formContainer, windowInfo.isMobile ? { width: '95%' } : { width: 400 }]}>
                    <ScrollView contentContainerStyle={styles.scrollContent}>
                        <MyAppText style={{ fontSize: 22, fontWeight: 'bold', color: theme.colors.labelColor }}>{translate('detectionsReport')}</MyAppText>
                        <FormInput
                            label={translate('name')}
                            value={name}
                            onChangeText={setName}
                            invalid={isNameInvalid}
                            errorMessage={translate('invalidCharacters')}
                        />
                        <FormInput label={translate('plate')}
                            invalid={() => false}
                            value={plate}
                            onChangeText={setPlate}
                        />
                        <CamerasDropdown value={selectedCameraFilter} setValue={setSelectedCameraFilter} zIndex={5} />
                        <BrandsDropdown value={brand} setValue={setBrand} zIndex={4} />
                        <ModelsDropdown value={model} setValue={setModel} zIndex={3} loadValues={brand !== ''} />
                        <ColorsDropdown value={color} setValue={setColor} zIndex={2} loadValues={model !== ''} />
                        <FilterInputNumeric
                            label={translate('year')}
                            value={year}
                            onChange={setYear}
                            placeholder={translate('typeAValue')}
                            maxValue={moment().year()}
                            minValue={1900}
                            disabled={color == ''}
                        />
                        <MyDatetimeInput
                            label={translate('beginIn')}
                            date={startInDateFilter}
                            onChangeDate={setStartInDateFilter}
                            time={startInHourFilter}
                            onChangeTime={setStartInHourFilter} />
                        <MyDatetimeInput
                            label={translate('endIn')}
                            date={endInDateFilter}
                            onChangeDate={setEndInDateFilter}
                            time={endInHourFilter}
                            onChangeTime={setEndInHourFilter} />
                    </ScrollView>
                    <FormActions
                        onSubmit={formSubmit}
                        onClose={() => onCloseNewReportModal()}
                        disabled={!isFormValid()}
                        confirmText={'download'} />
                </View>
            </View>
        </Modal>
    );
}

const styleSheet = createStyleSheet((theme) => ({
    container: {
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#31313199',
        flex: 1
    },
    formContainer: {
        minWidth: 300,
        minHeight: 150,
        backgroundColor: theme.colors.container.background,
        borderRadius: 4,
        rowGap: 15,
        maxHeight: 690,
        padding: 20
    },
    scrollContent: {
        rowGap: 15,
        padding: 5,
    }
}));
