import { BaseService, ClientError, ExtendableError, GenericPaginatedParameters, PartialBy, Unauthorized, UnexpectedError } from './base-service';
import { PaginatedResource } from '../../typings/Paginated';

export interface UpdatePersonParameters {
    name: string;
    isActive: boolean;
    document: string;
    comment: string;
    markers: number[];
    nickname: string;
    register: string;
    state: string;
    city: string;
    personType: PersonType;
    allowListGroupsIds: number[];

    // lawsuit fields
    arrestWarrantNumber: string;
    expirationDate: string;
    issuingBody: string;
    lawsuitCity: string;
    lawsuitNumber: string;
    magistrate: string;
    prisonKind: string;
    shippingDate: string;
    situation: string;
}

export interface GetFacialPeopleParameters extends GenericPaginatedParameters {
    nameOrDocument?: string;
    onlyActive?: boolean;
    markers?: number[];
    personType?: PersonType | '';
}

export interface GetFacialDetectionsParameters extends GenericPaginatedParameters {
    onlyWithPerson?: boolean;
    personId?: number;
    beggingIn?: number;
    endIn?: number;
    cameras?: string[];
    confidence?: number;
    personType?: PersonType;
}

export interface GetAllowListGroupsParameters extends GenericPaginatedParameters {
    personId?: number;
    cameras?: string[];
}

export class NoFaceOnPhoto extends ExtendableError { }

export class MoreThanOneFaceOnPhoto extends ExtendableError { }

export enum PersonType {
    WANTED = 'wanted',
    MISSING = 'missing',
    ALLOW_LIST = 'allow_list',
}

class FacialService extends BaseService {
    async getDetections(params: GetFacialDetectionsParameters): Promise<FacialDetection[]> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/facial/detections${this.encodeQueryParams({
            ...params,
            confidence: params.confidence ? (params.confidence / 100).toFixed(2) : undefined,
        })}`, {
            method: 'GET',
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async getFacialDetection(detectionId: number): Promise<FacialDetection> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/facial/detection/${detectionId}`, {
            method: 'GET',
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async getFacialMarkers(): Promise<FacialMarker[]> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/facial/markers`, {
            method: 'GET',
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async updateFacialMarker({ id, name, color }: PartialBy<Omit<FacialMarker, 'createdAt' | 'updatedAt'>, 'id'>): Promise<FacialMarker> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/facial/marker/${id}`, {
            method: 'PUT',
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
            body: JSON.stringify({ name, color }),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async deleteFacialMarker(id: number): Promise<void> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/facial/marker/${id}`, {
            method: 'DELETE',
            headers: this.getHeaders(token, 'application/json;charset=utf-8')
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }
    }

    async createFacialMarker({ name, color }: Omit<FacialMarker, 'id' | 'createdAt' | 'updatedAt'>): Promise<FacialMarker> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/facial/marker`, {
            method: 'POST',
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
            body: JSON.stringify({ name, color }),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async getCameras(): Promise<FacialSimplifiedCamera[]> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/facial/cameras`, {
            method: 'GET',
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status == 204) {
            return [];
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async getSimplifiedCameras(): Promise<FacialSimplifiedCamera[]> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/facial/simplified-cameras`, {
            method: 'GET',
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status == 204) {
            return [];
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async getSimplifiedFacialPeople(textFilter: string): Promise<(DropdownResource & { document?: string; })[]> {
        const token = await this.getToken();
        const res = await fetch(`${this.centralEndpoint}/facial/simplified-people${this.encodeQueryParams({ textFilter })}`, {
            method: 'GET',
            headers: this.getHeaders(token, 'application/json;charset=utf-8')
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async getFacialPeople(params: GetFacialPeopleParameters): Promise<PaginatedResource<FacialPerson>> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/facial/people${this.encodeQueryParams({ ...params })}`, {
            method: 'GET',
            headers: this.getHeaders(token, 'application/json;charset=utf-8')
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async getAllowListPeople(): Promise<FacialSimplifiedPerson[]> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/facial/simplified-allow-list-people`, {
            method: 'GET',
            headers: this.getHeaders(token, 'application/json;charset=utf-8')
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async getFacialPerson(id: number): Promise<FacialPerson> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/facial/person/${id}`, {
            method: 'GET',
            headers: this.getHeaders(token, 'application/json;charset=utf-8')
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async deletePerson(id: number): Promise<void> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/facial/person/${id}`, {
            method: 'DELETE',
            headers: this.getHeaders(token, 'application/json;charset=utf-8')
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

    }

    async addPerson(params: UpdatePersonParameters): Promise<FacialPerson> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/facial/person`, {
            method: 'POST',
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
            body: JSON.stringify(params),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async updatePerson(id: number, params: Omit<UpdatePersonParameters, 'personType'>): Promise<FacialPerson> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/facial/person/${id}`, {
            method: 'PUT',
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
            body: JSON.stringify(params),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async addFacePicture({ personId, photo }: { personId: number, photo: File; }): Promise<void> {
        const token = await this.getToken();

        const formData = new FormData();
        formData.append('faceImage', photo);

        const res = await fetch(`${this.centralEndpoint}/facial/person/${personId}/facePicture`, {
            method: 'POST',
            headers: this.getHeaders(token),
            body: formData
        });

        if (res.status == 400) {
            const resJson = await res.json();
            if (resJson.message == 'NO_FACE_ON_PHOTO') {
                throw new NoFaceOnPhoto('noFaceOnPhoto');
            }
            if (resJson.message == 'MORE_THAN_ONE_FACE_ON_PHOTO') {
                throw new MoreThanOneFaceOnPhoto('moreThanOneFaceOnPhoto');
            }
            throw new ClientError();
        }

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }
    }

    async deleteFacePicture({ personId, facePictureId }: { personId: number; facePictureId: number; }): Promise<void> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/facial/person/${personId}/facePicture/${facePictureId}`, {
            method: 'DELETE',
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }
    }

    async findPeopleByPicture({ photo, onlyActive }: { photo: File; onlyActive: boolean; }): Promise<{ people: FacialPerson[]; prev: string | null; next: string | null; }> {
        const token = await this.getToken();

        const formData = new FormData();
        formData.append('photo', photo);
        formData.append('onlyActive', String(onlyActive));

        const res = await fetch(`${this.centralEndpoint}/find-by-picture/people`, {
            method: 'POST',
            headers: this.getHeaders(token),
            body: formData
        });

        if (res.status == 400) {
            const resJson = await res.json();
            if (resJson.message == 'NO_FACE_ON_PHOTO') {
                throw new NoFaceOnPhoto();
            }
            if (resJson.message == 'MORE_THAN_ONE_FACE_ON_PHOTO') {
                throw new MoreThanOneFaceOnPhoto();
            }
            throw new ClientError();
        }

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async getAllowListGroup(id: number): Promise<AllowListGroup> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/facial/allow-list-group/${id}`, {
            method: 'GET',
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async getAllowListGroups(params: GetAllowListGroupsParameters): Promise<PaginatedResource<AllowListGroupForList>> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/facial/allow-list-groups${this.encodeQueryParams({ ...params })}`, {
            method: 'GET',
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async createAllowListGroup(params: Omit<AllowListGroup, 'id' | 'allowListCameras' | 'allowListPeople'> & {
        camerasToAdd: string[];
        peopleToAdd: number[];
    }): Promise<AllowListGroup> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/facial/allow-list-group`, {
            method: 'POST',
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
            body: JSON.stringify({ ...params }),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async updateAllowListGroup(params: Omit<PartialBy<AllowListGroup, 'id'>, | 'allowListCameras' | 'allowListPeople'> & {
        camerasToAdd: string[];
        peopleToAdd: number[];
        camerasToRm: string[];
        peopleToRm: number[];
    }): Promise<AllowListGroup> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/facial/allow-list-group/${params.id}`, {
            method: 'PUT',
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
            body: JSON.stringify({ ...params }),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }

        return res.json();
    }

    async deleteAllowListGroup(id: number): Promise<void> {
        const token = await this.getToken();

        const res = await fetch(`${this.centralEndpoint}/facial/allow-list-group/${id}`, {
            method: 'DELETE',
            headers: this.getHeaders(token, 'application/json;charset=utf-8'),
        });

        if (res.status == 401) {
            throw new Unauthorized();
        }

        if (res.status != 200) {
            throw new UnexpectedError();
        }
    }


}

export const facialService = new FacialService();
