import React, { useState } from 'react';
import FilterDropdown from '../Filter/components/FilterDropdown';
import { translate } from '../../services/translate';
import { facialService } from '../../services/central-api/facial';

interface Props {
    value: number;
    setValue: React.Dispatch<React.SetStateAction<number>>;
    zIndex: number;
}

function PeopleDropdown({ value, setValue, zIndex }: Props) {
    const [items, setItems] = useState<{ label: string, value: string; }[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [filterTimeout, setFilterTimeout] = useState<NodeJS.Timeout>();

    async function getItems(textFilter: string) {
        try {
            setIsLoading(true);
            const people = await facialService.getSimplifiedFacialPeople(textFilter);
            setItems(people.map((person) => {
                return {
                    value: String(person.id),
                    label: person.document ? `${person.document} - ${person.name}` : person.name
                };
            }));
        } catch (err) {
            console.error(err);
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <FilterDropdown
            label={translate('person')}
            placeholder={translate('searchNameDocument')}
            items={items}
            multiple={false}
            setValue={setValue}
            value={value}
            zIndex={zIndex}
            searchable={false}
            disableLocalSearch={true}
            loading={isLoading}
            onChangeSearchText={(text) => {
                if (text && text?.length > 2) {
                    if (filterTimeout) {
                        clearTimeout(filterTimeout);
                    }

                    const timeout = setTimeout(() => {
                        getItems(text);
                    }, 1000);

                    setFilterTimeout(timeout);
                }
            }}
        />
    );
}

export default PeopleDropdown;
