import React, { useMemo, useState } from 'react';
import CustomButton from '../CustomButton';
import { translate } from '../../services/translate';
import { dispatchService } from '../../services/central-api/dispatch';
import Toast from 'react-native-toast-message';
import { ClientError } from '../../services/central-api/base-service';

interface Props {
    occurrence: Occurrence;
}

function ExportPDFOccurrenceDetails({ occurrence }: Props) {
    const translationData = useMemo<Record<string, string>>(() => ({
        occurrenceDetails: translate('occurrenceDetails'),
        nProtocol: translate('nProtocol'),
        type: translate('type'),
        priority: translate('priority'),
        ...(occurrence.priority && {
            [occurrence.priority]: translate(occurrence.priority)
        }),
        ...(occurrence.triggerType && {
            [occurrence.triggerType]: translate(occurrence.triggerType)
        }),
        narrative: translate('narrative'),
        history: translate('historic'),
        name: translate('name'),
        addedAttachment: translate('addedAttachment'),
        occurrence_created_historic_title: translate('occurrence_created_historic_title'),
        occurrence_transfer: translate('occurrence_transfer'),
        occurrence_updated_historic_title: translate('occurrence_updated_historic_title'),
        unit_disassociated_to_occurrence_historic_title: translate('unit_disassociated_to_occurrence_historic_title'),
        unit_associated_to_occurrence_historic_title: translate('unit_associated_to_occurrence_historic_title'),
        unit_set_starter_historic_title: translate('unit_set_starter_historic_title'),
        unit_remove_displaced_historic_title: translate('unit_remove_displaced_historic_title'),
        unit_set_displaced_historic_title: translate('unit_set_displaced_historic_title'),
        reopened_occurrence: translate('reopened_occurrence'),
        finished_occurrence: translate('finished_occurrence'),
        occurrence_created_involved_vehicle: translate('occurrence_created_involved_vehicle'),
        occurrence_created_involved_person: translate('occurrence_created_involved_person'),
        occurrence_created_involved_object: translate('occurrence_created_involved_object'),
        occurrence_updated_involved_vehicle: translate('occurrence_updated_involved_vehicle'),
        occurrence_updated_involved_person: translate('occurrence_updated_involved_person'),
        occurrence_updated_involved_object: translate('occurrence_updated_involved_object'),
        occurrence_removed_involved_vehicle: translate('occurrence_removed_involved_vehicle'),
        occurrence_removed_involved_person: translate('occurrence_removed_involved_person'),
        occurrence_removed_involved_object: translate('occurrence_removed_involved_object'),
        plate: translate('plate'),
        registered: translate('registeredAt'),
        vehicle: translate('normalVehicle'),
        model: translate('model'),
        brand: translate('brand'),
        color: translate('color'),
        year: translate('year'),
        origin: translate('origin'),
        camera: translate('camera'),
        nickname: translate('nickname'),
        confidence: translate('confidence'),
        arrestWarrants: translate('arrestWarrants'),
        situation: translate('situation'),
        arrestWarrantNumber: translate('arrestWarrantNumber'),
        shippingDate: translate('shippingDate'),
        expirationDate: translate('expirationDate'),
        lawsuitNumber: translate('lawsuitNumber'),
        prisonKind: translate('prisonKind'),
        magistrate: translate('magistrate'),
        issuingBody: translate('issuingBody'),
        county: translate('county'),
        comments: translate('comments'),
        document: translate('document'),
        birth: translate('birth'),
        motherName: translate('motherName'),
        systemData: translate('systemData'),
        occurrenceNumber: translate('occurrenceNumber'),
        policeReportYear: translate('policeReportYear'),
        policeReportLocation: translate('policeReportLocation'),
        occurrenceDate: translate('occurrenceDate'),
        occurrenceUnit: translate('occurrenceUnit'),
        state: translate('state'),
        origination: translate('origination'),
        modelYear: translate('modelYear'),
        manufacturingYear: translate('manufacturingYear'),
        chassis: translate('chassis'),
        description: translate('description'),
        occurrenceSystem: translate('occurrenceSystem'),
        natureOfOccurrence: translate('natureOfOccurrence'),
        policeReportNumber: translate('policeReportNumber'),
        declarantName: translate('declarantName'),
        policeUnit: translate('policeUnit'),
        requester: translate('requester'),
        phone: translate('phone'),
        location: translate('location'),
        stolen: translate('stolen'),
        finishCategory: translate('finishCategory'),
        finishSubCategory: translate('finishSubCategory'),
        id: translate('id'),
        amount: translate('amount'),
        category: translate('category'),
        subCategory: translate('subCategory'),
        gender: translate('gender'),
        relation: translate('relation'),
        publicBody: translate('publicBody'),
        restriction: translate('restriction'),
        agents: translate('agents'),
        documentType: translate('documentType'),
        male: translate('male'),
        female: translate('female'),
        true: translate('true'),
        false: translate('false'),
        starter: translate('starter'),
        support: translate('support'),
        ongoing: translate('ongoing'),
        userNameOrWarName: translate('userNameOrWarName'),
        displaceReason: translate('displaceReason'),
        arrested: translate('arrested'),
        policeReport: translate('policeReport'),
        policeReportNature: translate('policeReportNature'),
        latitude: translate('latitude'),
        longitude: translate('longitude'),
        address: translate('address'),
        sigrc: translate('sigrc')

    }), [occurrence.triggerType, occurrence.id]);
    const [loading, setIsLoading] = useState(false);


    const createPDF = async () => {
        if (!occurrence.id) return;
        try {
            setIsLoading(true);
            const blob = await dispatchService.generatePDFOccurrence(occurrence.id, translationData);

            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = `${translate('occurrence')}-${occurrence.id}.pdf`;
            a.click();
            window.URL.revokeObjectURL(url);
            a.remove();
            Toast.show({
                type: 'sentinelxSuccess',
                text1: translate('ActionSuccessfully'),
            });
        } catch (err) {
            if (err instanceof ClientError) {
                return Toast.show({
                    type: 'sentinelxError',
                    text1: translate(err.message),
                });
            }

            console.error(err);
            Toast.show({
                type: 'sentinelxError',
                text1: translate('unexpectedError'),
            });
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <CustomButton
            text={translate('exportPDF')}
            onPress={() => createPDF()}
            style={{ minWidth: 158 }}
            isLoading={loading}
        />
    );
}

export default ExportPDFOccurrenceDetails;
