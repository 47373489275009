import React, { useContext, useEffect, useMemo } from 'react';
import { createStackNavigator, StackNavigationProp } from '@react-navigation/stack';
import moment from 'moment';

import Login from './pages/Login';
import Menu from './pages/Menu';
import ResetPassword from './pages/ResetPassword';
import TermsOfUse from './pages/TermsOfUse';
import ChangeLogs from './pages/ChangeLogs';
import ContactUs from './pages/ContactUs';

import { centralAPI } from './services/central-api';
import { translate } from './services/translate';

// cameras
import CamerasMap from './pages/cameras/CamerasMap';
import CameraPlayer from './pages/cameras/CameraPlayer/CameraPlayer';
import Mosaic from './pages/cameras/Mosaic/Mosaic';
import MosaicDetail from './pages/cameras/Mosaic/MosaicDetail';
import CamerasList from './pages/cameras/Cameras/CamerasList';
import CameraDetails from './pages/cameras/Cameras/CameraDetails';
import CameraTags from './pages/cameras/CameraTags/CameraTags';
import Downloads from './pages/cameras/Downloads/Downloads';
import DownloadTags from './pages/cameras/DownloadTags/DownloadTags';
import VideoAnalysis from './pages/cameras/VideoAnalysis/index';
import VideoAnalysisDetails from './pages/cameras/VideoAnalysisDetails/index';
import AlertGroups from './pages/cameras/AlertGroups/Index';
import MapLprDetection from './pages/cameras/SearchLprDetection';

// dispatch
import Resume from './pages/dispatch/Resume';
import Occurrences from './pages/dispatch/Occurrences/Occurrences';
import DispatchOccurrenceDetail from './pages/dispatch/DispatchOccurrenceDetail';
import OccurrenceTypes from './pages/dispatch/OccurrenceTypes/OccurrenceTypes';
import Equipment from './pages/dispatch/Equipments/Equipment';
import DispatchReports from './pages/dispatch/Reports/Reports';
import Skills from './pages/dispatch/Skills/Skill';
import Units from './pages/dispatch/Units/Units';
import Assignments from './pages/dispatch/Assignments/Assignment';
import OccurrenceTags from './pages/dispatch/OccurrenceTags/OccurrenceTags';
import DispatchReportTags from './pages/dispatch/ReportTags/ReportTags';
import ObjectsCategories from './pages/dispatch/ObjectCategories/ObjectCategory';
import FinishCategories from './pages/dispatch/FinishCategories/index';
import TeamNames from './pages/dispatch/TeamNames/TeamNames';
import VehicleRelations from './pages/dispatch/VehicleRelation/VehicleRelations';
import PersonRelations from './pages/dispatch/PersonRelation/PersonRelations';

// facial
import LiveEvents from './pages/facial/LiveEvents/LiveEvents';
import LiveAlerts from './pages/facial/LiveAlerts';
import Search from './pages/facial/Search/Search';
import Markers from './pages/facial/Markers';
import PeopleList from './pages/facial/People/PeopleList';
import PersonForm from './pages/facial/PersonForm';
import LiveEventDetail from './pages/facial/LiveEvents/LiveEventDetail';
import LiveAlertEventDetail from './pages/facial/LiveAlertEventDetail';
import SearchEventDetail from './pages/facial/SearchEventDetail';
import AllowListGroup from './pages/facial/AllowListGroup/AllowListGroup';

// lpr
import LprLiveEvents from './pages/lpr/LiveEvents/LiveEvents';
import LprLiveAlerts from './pages/lpr/LiveAlerts';
import LprSearch from './pages/lpr/Search';
import Plates from './pages/lpr/Plates';
import LprReports from './pages/lpr/Reports/Reports';
import LiveAlertLprDetection from './pages/lpr/Detection/LiveAlertLprDetection';
import LiveEventLprDetection from './pages/lpr/Detection/LiveEventLprDetection';
import SearchLprDetection from './pages/lpr/Detection/SearchLprDetection';

// guardian app
import GuardianAppUsers from './pages/guardian-app/Users';

// access
import Users from './pages/access/Users/index';
import UserTags from './pages/access/UserTags';
import PermissionLevels from './pages/access/PermissionLevels/PermissionLevels';
import Groups from './pages/access/Groups/Groups';
import InstallationCompanies from './pages/access/InstallationCompanies';
import InternetCompanies from './pages/access/InternetCompanies';
import ActingBodies from './pages/access/ActingBodies/ActingBodies';
import PlatformSettings from './pages/access/PlatformSettings';
import PtzPriority from './pages/access/PtzPriority';
import Turnstiles from './pages/access/Turnstiles';

// events
import EventsBoard from './pages/events/EventsBoard';
import EventsOccurrenceDetail from './pages/events/EventsOccurrenceDetail';

// GCM Agent
import PersonOfInterest from './pages/gcm-agent/PersonOfInterest';
import AgentOccurrences from './pages/gcm-agent/AgentOccurrences';
import AgentOccurrenceDetails from './pages/gcm-agent/AgentOccurrenceDetails';
import AgentUnits from './pages/gcm-agent/AgentUnits/AgentUnits';
import CortexFinder from './pages/gcm-agent/CortexFinder/CortexFinder';

// Dashboard
import DashboardCameras from './pages/dashboard/DashboardCameras';
import DashboardOccurrences from './pages/dashboard/DashboardOccurrences';
import DashboardAgents from './pages/dashboard/DashboardAgents/DashboardAgents';

// Logs
import LogList from './pages/logs/LogList/LogList';
import LogReports from './pages/logs/Reports/Reports';

// Smart Search Facial
import SmartSearchFacial from './pages/smart-search-facial/SmartSearchFacial';
import SmartSearchEventDetail from './pages/smart-search-facial/SmartSearchEventDetail';

// User
import Account from './pages/user/Account';
import Password from './pages/user/Password';
import Sessions from './pages/user/Sessions';

// CRM
import Management from './pages/crm/Management/Management';
import AlarmCentersList from './pages/crm/AlarmCentersList';
import ManagementDetails from './pages/crm/ManagementDetails/ManagementDetails';
import AlarmCenterDetails from './pages/crm/AlarmCenterDetails';
import { MyMessage } from './components/MyMessage';
import CameraHistory from './pages/crm/CameraHistory/index';
import OfflineEvents from './pages/crm/OfflineEvents/index';
import MaintenanceRequests from './pages/crm/MaintenanceRequests/index';

//Param typings
import type { FacialParamList, LPRParamList, AccessParamList, CRMParamList, CamerasParamList, DispatchParamList, GuardianAppParamList, LogsParamList, EventParamList, DashBoardParamList, SmartSearchFacialParamList, UserParamList, RootStackParamList } from './typings/Params';
import { useAuth } from './context/Auth';
import getThemedColor from './services/get-themed-color';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import ThemeContext from './context/Theme';
import { DefaultTheme, NavigationContainer, useNavigation, useNavigationContainerRef } from '@react-navigation/native';
import DefaultPageContainer from './components/DefaultPageContainer';
import DefaultPageLayout from './components/DefaultPageLayout';

const Stack = createStackNavigator<RootStackParamList>();
const DispatchStack = createStackNavigator<DispatchParamList>(); // Create a separate stack for 'Dispatch' and its sub-pages
const CamerasStack = createStackNavigator<CamerasParamList>(); // Create a separate stack for 'CameraMap' and its sub-pages
const FacialStack = createStackNavigator<FacialParamList>(); // Create a separate stack for 'FacialStack' and its sub-pages
const LprStack = createStackNavigator<LPRParamList>(); // Create a separate stack for 'LprStack' and its sub-pages
const GuardianAppStack = createStackNavigator<GuardianAppParamList>(); // Create a separate stack for 'AccessStack' and its sub-pages
const AccessStack = createStackNavigator<AccessParamList>(); // Create a separate stack for 'AccessStack' and its sub-pages
const EventStack = createStackNavigator<EventParamList>(); // Create a separate stack for 'EventStack' and its sub-pages
const DashboardStack = createStackNavigator<DashBoardParamList>(); // Create a separate stack for 'DashboardStack' and its sub-pages
const LogsStack = createStackNavigator<LogsParamList>(); // Create a separate stack for 'LogsStack' and its sub-pages
const SmartSearchFacialStack = createStackNavigator<SmartSearchFacialParamList>(); // Create a separate stack for 'SmartSearchFacialStack' and its sub-pages
const CrmStack = createStackNavigator<CRMParamList>(); // Create a separate stack for 'CrmStack' and its sub-pages
const UserStack = createStackNavigator<UserParamList>(); // Create a separate stack for 'USerStack' and its sub-pages

interface Screens {
    [pageName: string]: {
        path: string;
        screens?: Screens;
        module?: string;
        selectedItem?: string;
        parse?: {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            [queryParam: string]: any;
        };
    };
}

interface RouteConfig {
    screens: Screens;
}

const config: RouteConfig = {
    screens: {
        MainPage: {
            path: ''
        },
        TermsOfUse: {
            path: '/terms-of-use'
        },
        ChangeLogs: {
            path: '/change-logs',
        },
        ContactUs: {
            path: '/contact-us',
        },
        Login: {
            path: '/login',
        },
        Menu: {
            path: '/menu',
        },
        ResetPassword: {
            path: '/reset-password/:token'
        },
        Dispatch: {
            path: '/dispatch',
            screens: {
                Resume: {
                    path: 'resume',
                    module: 'dispatch',
                    selectedItem: 'resume',
                },
                TeamNames: {
                    path: 'team-names',
                    module: 'dispatch',
                    selectedItem: 'team-names',
                    parse: {
                        page: Number,
                    }
                },
                Units: {
                    path: 'units',
                    module: 'dispatch',
                    selectedItem: 'units',
                    parse: {
                        page: Number,
                        actingBodyId: Number,
                        actingBodyUnitIds: (actingBodyUnitIds: string) => actingBodyUnitIds ? actingBodyUnitIds.split(',') : []
                    }
                },
                ObjectsCategories: {
                    path: 'objects-categories',
                    module: 'dispatch',
                    selectedItem: 'objects-categories',
                    parse: {
                        page: Number,
                    }
                },
                FinishCategories: {
                    path: 'finish-categories',
                    module: 'dispatch',
                    selectedItem: 'finish-categories',
                    parse: {
                        page: Number
                    }
                },
                Occurrences: {
                    path: 'occurrences',
                    module: 'dispatch',
                    selectedItem: 'occurrences',
                    parse: {
                        page: Number,
                        tags: (tags: string) => {
                            return tags.length > 0 ? tags.split(',').map(t => +t) : [];
                        }
                    }
                },
                DispatchOccurrenceDetail: {
                    path: 'occurrences/detail/:occurrenceId?',
                    module: 'dispatch',
                    selectedItem: 'occurrences',
                },
                OccurrenceTypes: {
                    path: 'occurrence-types',
                    module: 'dispatch',
                    selectedItem: 'occurrence-types',
                    parse: {
                        page: Number,
                        limit: Number,
                        isActive: (isActive: string) => isActive === 'true',
                    }
                },
                Equipments: {
                    path: 'equipments',
                    module: 'dispatch',
                    selectedItem: 'equipments',
                    parse: {
                        page: Number,
                    }
                },
                VehicleRelations: {
                    path: 'vehicle-relations',
                    module: 'dispatch',
                    selectedItem: 'vehicle-relations',
                    parse: {
                        page: Number,
                    }
                },
                PersonRelations: {
                    path: 'person-relations',
                    module: 'dispatch',
                    selectedItem: 'person-relations',
                    parse: {
                        page: Number,
                    }
                },
                DispatchReports: {
                    path: 'reports',
                    module: 'dispatch',
                    selectedItem: 'reports',
                    parse: {
                        page: Number,
                    }
                },
                Skills: {
                    path: 'skills',
                    module: 'dispatch',
                    selectedItem: 'skills',
                    parse: {
                        page: Number,
                    }
                },
                Assignments: {
                    path: 'assignments',
                    module: 'dispatch',
                    selectedItem: 'assignments',
                    parse: {
                        page: Number,
                    }
                },
                OccurrenceTags: {
                    path: 'occurrence-tags',
                    module: 'dispatch',
                    selectedItem: 'occurrence-tags',
                    parse: {
                        page: Number,
                    }
                },
                ReportTags: {
                    path: 'report-tags',
                    module: 'dispatch',
                    selectedItem: 'report-tags',
                    parse: {
                        page: Number,
                    }
                },
            },
        },
        CameraPlayer: {
            path: '/cameras/map/:id',
            module: 'cameras',
            selectedItem: 'map',
        },
        Cameras: {
            path: '/cameras',
            screens: {
                CamerasMap: {
                    path: 'map',
                    module: 'cameras',
                    selectedItem: 'map',
                },
                CamerasList: {
                    path: 'cameras',
                    module: 'cameras',
                    selectedItem: 'cameras',
                    parse: {
                        page: Number,
                        offline: (offline: string) => offline === 'true',
                    }
                },
                CamerasHiddenExport: {
                    path: 'hidden-export',
                    module: 'cameras',
                    selectedItem: 'cameras',
                    parse: {
                        page: Number,
                        offline: (offline: string) => offline === 'true',
                    }
                },
                CameraDetails: {
                    path: 'cameras/details/:id?',
                    module: 'cameras',
                    selectedItem: 'cameras',
                },
                Mosaic: {
                    path: 'mosaic',
                    module: 'cameras',
                    selectedItem: 'mosaic',
                    parse: {
                        page: Number,
                    }
                },
                MosaicDetail: {
                    path: 'mosaic/:id',
                    module: 'cameras',
                    selectedItem: 'mosaic',
                },
                CameraTags: {
                    path: 'camera-tags',
                    module: 'cameras',
                    selectedItem: 'camera-tags',
                },
                Downloads: {
                    path: 'downloads',
                    module: 'cameras',
                    selectedItem: 'downloads',
                    parse: {
                        page: Number,
                    }
                },
                DownloadTags: {
                    path: 'download-tags',
                    module: 'cameras',
                    selectedItem: 'download-tags',
                },
                VideoAnalysis: {
                    path: 'video-analysis',
                    module: 'cameras',
                    selectedItem: 'video-analysis',
                    parse: {
                        requesterUserId: Number,
                        page: Number,
                        limit: Number
                    }
                },
                VideoAnalysisDetails: {
                    path: 'video-analysis/details/:id',
                    module: 'cameras',
                    selectedItem: 'video-analysis',
                    parse: {
                        id: Number
                    }
                },
                AlertGroups: {
                    path: 'alert-groups',
                    module: 'cameras',
                    selectedItem: 'alert-groups',
                    parse: {
                        page: Number,
                    }
                },
            },
        },
        SmartSearchEventDetail: {
            path: '/smart-search-facial/search/event/:eventId',
            module: 'smart-search-facial',
            selectedItem: 'search',
        },
        LiveEventDetail: {
            path: '/facial/live-events/event/:eventId',
            module: 'facial',
            selectedItem: 'live-events',
        },
        LiveAlertEventDetail: {
            path: '/facial/live-alerts/event/:eventId',
            module: 'facial',
            selectedItem: 'live-alerts',
        },
        SearchEventDetail: {
            path: '/facial/search/event/:eventId',
            module: 'facial',
            selectedItem: 'search',
        },
        Facial: {
            path: '/facial',
            screens: {
                LiveEvents: {
                    path: 'live-events',
                    module: 'facial',
                    selectedItem: 'live-events',
                    parse: {
                        page: Number,
                        cameras: (cameras: string) => cameras ? cameras.split(',') : [],
                    }
                },
                LiveAlerts: {
                    path: 'live-alerts',
                    module: 'facial',
                    selectedItem: 'live-alerts',
                },
                Search: {
                    path: 'search',
                    module: 'facial',
                    selectedItem: 'search',
                    parse: {
                        page: Number,
                        onlyWithPerson: (onlyWithPerson: string) => onlyWithPerson === 'true',
                        personId: Number,
                        beggingIn: Number,
                        endIn: Number,
                        confidence: Number,
                        cameras: (cameras: string) => cameras ? cameras.split(',') : [],
                        markers: (markers: string) => {
                            return markers.length > 0 ? markers.split(',').map(m => +m) : [];
                        },
                    }
                },
                Markers: {
                    path: 'markers',
                    module: 'facial',
                    selectedItem: 'markers',
                },
                PeopleList: {
                    path: 'people',
                    module: 'facial',
                    selectedItem: 'people',
                    parse: {
                        page: Number,
                        onlyActive: (active: string) => active === 'true',
                        markers: (markers: string) => {
                            return markers.length > 0 ? markers.split(',').map(m => +m) : [];
                        },
                    }
                },
                AllowListGroup: {
                    path: 'allow-list-group',
                    module: 'facial',
                    selectedItem: 'allow-list-group',
                    parse: {
                        page: Number,
                        personId: Number,
                        cameras: (cameras: string) => cameras ? cameras.split(',') : [],
                    }
                },
                PersonForm: {
                    path: '/people/form/:id?',
                    module: 'facial',
                    selectedItem: 'people',
                },
            }
        },
        SmartSearchFacial: {
            path: '/smart-search-facial',
            screens: {
                SmartSearchFacial: {
                    path: 'search',
                    module: 'smart-search-facial',
                    selectedItem: 'search',
                },
            }
        },
        User: {
            path: '/user',
            screens: {
                Account: {
                    path: 'account',
                    module: 'user',
                    selectedItem: 'account',
                },
                Password: {
                    path: 'password',
                    module: 'user',
                    selectedItem: 'password',
                },
                Sessions: {
                    path: 'sessions',
                    module: 'user',
                    selectedItem: 'sessions',
                },
            }
        },
        MapLprDetection: {
            path: 'cameras/map/lpr-detection/:detectionId',
            module: 'cameras',
            selectedItem: 'map',
        },
        SearchLprDetection: {
            path: 'LPR/search/lpr-detection/:detectionId',
            module: 'LPR',
            selectedItem: 'search',
        },
        LiveEventLprDetection: {
            path: 'LPR/live-events/lpr-detection/:detectionId',
            module: 'LPR',
            selectedItem: 'live-events',
        },
        LiveAlertLprDetection: {
            path: 'LPR/live-alerts/lpr-detection/:detectionId',
            module: 'LPR',
            selectedItem: 'live-alerts',
        },
        LPR: {
            path: '/LPR',
            screens: {
                LprLiveEvents: {
                    path: 'live-events',
                    module: 'LPR',
                    selectedItem: 'live-events',
                    parse: {
                        page: Number,
                        cameras: (cameras: string) => cameras ? cameras.split(',') : [],
                    }
                },
                LprLiveAlerts: {
                    path: 'live-alerts',
                    module: 'LPR',
                    selectedItem: 'live-alerts',
                },
                LprSearch: {
                    path: 'search',
                    module: 'LPR',
                    selectedItem: 'search',
                    parse: {
                        page: Number,
                        irregularSituation: (irregularSituation: string) => irregularSituation === 'true',
                        isMotorcycle: (isMotorcycle: string) => isMotorcycle === 'true',
                        beggingIn: Number,
                        endIn: Number,
                        cameras: (cameras: string) => cameras ? cameras.split(',') : []
                    }
                },
                Plates: {
                    path: 'plates',
                    module: 'LPR',
                    selectedItem: 'plates',
                    parse: {
                        page: Number,
                        onlyActive: (active: string) => active === 'true',
                    }
                },
                LprReports: {
                    path: 'reports',
                    module: 'LPR',
                    selectedItem: 'reports',
                    parse: {
                        page: Number,
                    }
                },
            }
        },
        ManagementDetails: {
            path: '/CRM/cameras/details/:id?',
            module: 'CRM',
            selectedItem: 'cameras',
        },
        AlarmCenterDetails: {
            path: '/CRM/alarm-centers/details/:id?',
            module: 'CRM',
            selectedItem: 'alarm-centers',
        },
        CRM: {
            path: '/CRM',
            screens: {
                Management: {
                    path: 'cameras',
                    module: 'CRM',
                    selectedItem: 'cameras',
                },
                CameraHistory: {
                    path: 'cameras/history/:id',
                    module: 'CRM',
                    selectedItem: 'cameras',
                    parse: {
                        page: Number,
                    }
                },
                CrmHiddenExport: {
                    path: 'cameras/hidden-export',
                    module: 'CRM',
                    selectedItem: 'cameras',
                    parse: {
                        page: Number,
                        offline: (offline: string) => offline === 'true',
                    }
                },
                AlarmCentersList: {
                    path: 'alarm-centers',
                    module: 'CRM',
                    selectedItem: 'alarm-centers',
                },
                OfflineEvents: {
                    path: 'offline-events',
                    module: 'CRM',
                    selectedItem: 'offline-events',
                },
                MaintenanceRequests: {
                    path: 'maintenance-requests',
                    module: 'CRM',
                    selectedItem: 'maintenance-requests',
                },
            }
        },
        GuardianApp: {
            path: '/guardian-app',
            screens: {
                GuardianAppUsers: {
                    path: 'users',
                    module: 'guardian-app',
                    selectedItem: 'users',
                    parse: {
                        page: Number,
                    }
                },
            }
        },
        Access: {
            path: '/access',
            screens: {
                Users: {
                    path: 'users',
                    module: 'access',
                    selectedItem: 'users',
                    parse: {
                        page: Number,
                        role: Number
                    }
                },
                PermissionLevels: {
                    path: 'permission-levels',
                    module: 'access',
                    selectedItem: 'permission-levels',
                    parse: {
                        page: Number,
                    }
                },
                Turnstiles: {
                    path: 'turnstiles',
                    module: 'access',
                    selectedItem: 'turnstiles',
                    parse: {
                        page: Number,
                    }
                },
                Groups: {
                    path: 'groups',
                    module: 'access',
                    selectedItem: 'groups',
                    parse: {
                        page: Number,
                    }
                },
                InstallationCompanies: {
                    path: 'installation-companies',
                    module: 'access',
                    selectedItem: 'installation-companies',
                    parse: {
                        page: Number,
                    }
                },
                InternetCompanies: {
                    path: 'internet-companies',
                    module: 'access',
                    selectedItem: 'internet-companies',
                    parse: {
                        page: Number,
                    }
                },
                ActingBodies: {
                    path: 'acting-bodies',
                    module: 'access',
                    selectedItem: 'acting-bodies',
                    parse: {
                        page: Number,
                    }
                },
                PlatformSettings: {
                    path: 'platform-settings',
                    module: 'access',
                    selectedItem: 'platform-settings',
                },
                UserTags: {
                    path: 'user-tags',
                    module: 'access',
                    selectedItem: 'user-tags',
                },
                PtzPriority: {
                    path: 'ptz-priority',
                    module: 'access',
                    selectedItem: 'ptz-priority',
                },
            }
        },
        Logs: {
            path: '/logs',
            screens: {
                LogList: {
                    path: 'search',
                    module: 'logs',
                    selectedItem: 'search',
                    parse: {
                        userId: Number,
                        sort: (sort: string) => sort === 'asc' ? sort : 'desc',
                        dateGte: Number,
                        dateLte: Number,
                    }
                },
                LogReports: {
                    path: 'reports',
                    module: 'logs',
                    selectedItem: 'reports',
                    parse: {
                        page: Number,
                    }
                },
            }
        },
        Events: {
            path: '/events',
            screens: {
                EventsBoard: {
                    path: 'events',
                    module: 'events',
                    selectedItem: 'events',
                    parse: {
                        beginIn: Number,
                        endIn: Number,
                        triggerType: (triggerType: string) => {
                            return triggerType.length > 0 ? triggerType.split(',') : [];
                        }
                    }
                },
                EventsOccurrenceDetail: {
                    path: 'events/detail/:occurrenceId?',
                    module: 'events',
                    selectedItem: 'events',
                },
            }
        },
        GcmAgent: {
            path: '/gcm-agent',
            screens: {
                PersonOfInterest: {
                    path: 'person-of-interest',
                    module: 'gcm-agent',
                    selectedItem: 'person-of-interest',
                },
                AgentOccurrences: {
                    path: 'occurrences',
                    module: 'gcm-agent',
                    selectedItem: 'occurrences',
                },
                AgentOccurrenceDetails: {
                    path: 'occurrences/:occurrenceId',
                    module: 'gcm-agent',
                    selectedItem: 'occurrences',
                },
                AgentUnits: {
                    path: 'units',
                    module: 'gcm-agent',
                    selectedItem: 'units',
                },
                CortexFinder: {
                    path: 'cortex-finder',
                    module: 'gcm-agent',
                    selectedItem: 'cortex-finder',
                },
            }
        },
        Dashboard: {
            path: '/dashboard',
            screens: {
                DashboardCameras: {
                    module: 'dashboard',
                    selectedItem: 'cameras',
                    path: 'cameras',
                },
                DashboardOccurrences: {
                    module: 'dashboard',
                    selectedItem: 'occurrences',
                    path: 'occurrences',
                },
                DashboardAgents: {
                    module: 'dashboard',
                    selectedItem: 'agents',
                    path: 'agents',
                    parse: {
                        page: Number,
                        unitIds: (units: string) => {
                            return units.length > 0 ? units.split(',').map(u => +u) : [];
                        },
                        actingBodyId: Number,
                        actingBodyIdUnitId: Number
                    }
                },
            }
        }
    },
};

export const pages: { [screen: string]: { module?: string; selectedItem?: string; }; } = {};


function getNestedScreens(screens: Screens | undefined) {
    if (!screens) {
        return;
    }
    for (const screen of Object.keys(screens)) {
        if (typeof screens[screen] == 'object') {
            const screenObj = screens[screen];
            if (screenObj.module && screenObj.selectedItem) {
                pages[screen] = { module: screenObj.module, selectedItem: screenObj.selectedItem };
                delete screens[screen].module;
                delete screens[screen].selectedItem;
            }
            if (screens[screen].screens && typeof screens[screen].screens !== 'string') {
                getNestedScreens(screens[screen].screens);
            }
        }
    }
}

getNestedScreens(config.screens);

export const linking = {
    prefixes: ['https://sentinelx.com.br'],
    config,
};

interface DefaultParams {
    navigation: Navigation,
    authenticatedUser?: AuthenticatedUser,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    route: any;
}

function validateSession(component: ({ navigation, authenticatedUser, route }: DefaultParams) => JSX.Element) {

    return ({ navigation, route }: DefaultParams) => {
        const [authenticatedUser, setAuthenticatedUser] = React.useState<AuthenticatedUser>();

        const { setUser } = useAuth();

        async function checkSessionStatus() {
            try {
                const user = await centralAPI.checkAuthentication();
                setAuthenticatedUser(user);

                setUser(user);

                if (!user.acceptedTermsOfUse) {
                    return navigation.navigate('TermsOfUse');
                }
            } catch (e) {
                MyMessage.show({ type: 'invalidAuth' });
            }
        }

        useEffect(() => {
            checkSessionStatus();
        }, []);

        return component({ navigation, authenticatedUser, route });
    };
}

const PagesRoutes = ({ route }: DefaultParams) => {

    const navigation = useNavigation<StackNavigationProp<RootStackParamList>>();

    useEffect(() => {
        const timeout = setTimeout(() => {
            if (route?.params?.pathname) {
                const path = route.params.pathname.split('/');

                if (path.length == 3 && path[1] == 'reset-password') {
                    navigation.navigate('ResetPassword');
                }

                if (path.length == 2 && path[1] == 'terms-of-use') {
                    navigation.navigate('TermsOfUse');
                }
            }
            navigation.reset({
                index: 0,
                routes: [{ name: 'Login' }],
            });
        }, 0);

        return () => clearTimeout(timeout);
    }, [navigation, route]);

    return <></>;
};

export function MySafeAreaProvider(props: {
    children: React.ReactNode | undefined;
}) {
    const { theme } = useContext(ThemeContext);
    const [, updateState] = React.useState({});
    const forceUpdate = React.useCallback(() => updateState({}), []);

    const navigationRef = useNavigationContainerRef<RootStackParamList>();

    navigationRef.addListener('state', () => {
        forceUpdate();
    });

    const pathname = useMemo(() => window.location.pathname, [window.location.pathname]);

    return <SafeAreaProvider
        style={{
            backgroundColor: getThemedColor(theme, '#F7F7F7')
        }}
    >
        <NavigationContainer ref={navigationRef} linking={linking} theme={{
            ...DefaultTheme,
            colors: {
                ...DefaultTheme.colors,
                background: getThemedColor(theme, '#F7F7F7')
            }
        }} >
            {pathname.includes('/reset-password/') || pathname.includes('/login') ?
                props.children
                : <DefaultPageContainer >
                    <DefaultPageLayout contentContainerStyle={{ padding: 10 }} navigationRef={navigationRef}>
                        {props.children}
                    </DefaultPageLayout>
                </DefaultPageContainer>
            }
        </NavigationContainer>
    </SafeAreaProvider>;
}

export default function MainRoutes() {
    return (
        // this is the background color for the bottom-bar of the app
        <Stack.Navigator>
            <Stack.Screen name='MainPage' component={PagesRoutes} options={{ headerShown: false }} />
            <Stack.Screen name='ChangeLogs' component={validateSession(ChangeLogs)} options={{ headerShown: false, title: translate('changeLogs') }} />
            <Stack.Screen name='ContactUs' component={validateSession(ContactUs)} options={{ headerShown: false, title: translate('contactUs') }} />
            <Stack.Screen name='TermsOfUse' component={validateSession(TermsOfUse)} options={{ headerShown: false, title: translate('termsOfUse') }} />
            <Stack.Screen name='ResetPassword' component={ResetPassword} options={{ headerShown: false, title: translate('resetPassword') }} />
            <Stack.Screen name='Login' component={Login} options={{ headerShown: false, title: translate('login') }} />
            <Stack.Screen name='Menu' component={validateSession(Menu)} options={{ headerShown: false, title: translate('menu') }} />
            <Stack.Screen name='Dispatch' options={{ headerShown: false }}>
                {() => (
                    <DispatchStack.Navigator initialRouteName='Resume'>
                        <DispatchStack.Screen name='Resume' component={validateSession(Resume)} options={{ headerShown: false, title: translate('dispatch') + ' ' + translate('resume') }} />
                        <DispatchStack.Screen name='Occurrences' component={validateSession(Occurrences)} initialParams={{ page: 1 }} options={{ headerShown: false, title: translate('occurrences') }} />
                        <DispatchStack.Screen name='DispatchOccurrenceDetail' component={validateSession(DispatchOccurrenceDetail)} options={{ headerShown: false, title: translate('occurrence') }} />
                        <DispatchStack.Screen name='OccurrenceTypes' component={validateSession(OccurrenceTypes)} initialParams={{ page: 1, isActive: true }} options={{ headerShown: false, title: translate('occurrence_types') }} />
                        <DispatchStack.Screen name='Equipments' component={validateSession(Equipment)} initialParams={{ page: 1 }} options={{ headerShown: false, title: translate('equipments') }} />
                        <DispatchStack.Screen name='VehicleRelations' component={validateSession(VehicleRelations)} initialParams={{ page: 1 }} options={{ headerShown: false, title: translate('vehicleRelations') }} />
                        <DispatchStack.Screen name='PersonRelations' component={validateSession(PersonRelations)} initialParams={{ page: 1 }} options={{ headerShown: false, title: translate('personRelations') }} />
                        <DispatchStack.Screen name='DispatchReports' component={validateSession(DispatchReports)} initialParams={{ page: 1 }} options={{ headerShown: false, title: translate('reports') }} />
                        <DispatchStack.Screen name='Skills' component={validateSession(Skills)} initialParams={{ page: 1 }} options={{ headerShown: false, title: translate('skills') }} />
                        <DispatchStack.Screen name='Units' component={validateSession(Units)} initialParams={{ page: 1 }} options={{ headerShown: false, title: translate('teams') }} />
                        <DispatchStack.Screen name='ObjectsCategories' component={validateSession(ObjectsCategories)} initialParams={{ page: 1 }} options={{ headerShown: false, title: translate('objectsCategories') }} />
                        <DispatchStack.Screen name='FinishCategories' component={validateSession(FinishCategories)} initialParams={{ page: 1 }} options={{ headerShown: false, title: translate('finishCategories') }} />
                        <DispatchStack.Screen name='Assignments' component={validateSession(Assignments)} initialParams={{ page: 1 }} options={{ headerShown: false, title: translate('assignments') }} />
                        <DispatchStack.Screen name='OccurrenceTags' component={validateSession(OccurrenceTags)} initialParams={{ page: 1 }} options={{ headerShown: false, title: translate('occurrenceTags') }} />
                        <DispatchStack.Screen name='ReportTags' component={validateSession(DispatchReportTags)} initialParams={{ page: 1 }} options={{ headerShown: false, title: translate('reportTags') }} />
                        <DispatchStack.Screen name='TeamNames' component={validateSession(TeamNames)} initialParams={{ page: 1 }} options={{ headerShown: false, title: translate('teamNames') }} />
                    </DispatchStack.Navigator>
                )}
            </Stack.Screen>
            <Stack.Screen name='CameraPlayer' component={validateSession(CameraPlayer)} options={{ headerShown: false, title: translate('camera') }} />
            <Stack.Screen name='Cameras' options={{ headerShown: false }}>
                {() => (
                    <CamerasStack.Navigator initialRouteName='CamerasMap'>
                        <CamerasStack.Screen name='CamerasMap' component={validateSession(CamerasMap)} options={{ headerShown: false, title: translate('map') }} />
                        <CamerasStack.Screen name='Mosaic' component={validateSession(Mosaic)} initialParams={{ page: 1 }} options={{ headerShown: false, title: translate('mosaic') }} />
                        <CamerasStack.Screen name='CamerasList' component={validateSession(CamerasList)} initialParams={{ page: 1 }} options={{ headerShown: false, title: translate('cameras') }} />
                        <CamerasStack.Screen name='CameraDetails' component={validateSession(CameraDetails)} options={{ headerShown: false, title: translate('camera') }} />
                        <CamerasStack.Screen name='MosaicDetail' component={validateSession(MosaicDetail)} options={{ headerShown: false, title: translate('mosaic') }} />
                        <CamerasStack.Screen name='CameraTags' component={validateSession(CameraTags)} initialParams={{ page: 1 }} options={{ headerShown: false, title: translate('tags') }} />
                        <CamerasStack.Screen name='Downloads' component={validateSession(Downloads)} initialParams={{ page: 1 }} options={{ headerShown: false, title: 'Downloads' }} />
                        <CamerasStack.Screen name='DownloadTags' component={validateSession(DownloadTags)} initialParams={{ page: 1 }} options={{ headerShown: false, title: translate('downloadsTags') }} />
                        <CamerasStack.Screen name='VideoAnalysis' component={validateSession(VideoAnalysis)} initialParams={{ page: 1 }} options={{ headerShown: false, title: translate('videoAnalysis') }} />
                        <CamerasStack.Screen name='VideoAnalysisDetails' component={validateSession(VideoAnalysisDetails)} options={{ headerShown: false, title: translate('videoAnalysis') }} />
                        <CamerasStack.Screen name='CamerasHiddenExport' component={validateSession(CamerasList)} initialParams={{ page: 1 }} options={{ headerShown: false, title: translate('cameras') }} />
                        <CamerasStack.Screen name='AlertGroups' component={validateSession(AlertGroups)} initialParams={{ page: 1 }} options={{ headerShown: false, title: translate('alertGroups') }} />
                    </CamerasStack.Navigator >
                )
                }
            </Stack.Screen >
            <Stack.Screen name='SmartSearchEventDetail' component={validateSession(SmartSearchEventDetail)} options={{ headerShown: false, title: translate('detection') }} />
            <Stack.Screen name='LiveEventDetail' component={validateSession(LiveEventDetail)} options={{ headerShown: false, title: translate('detection') }} />
            <Stack.Screen name='LiveAlertEventDetail' component={validateSession(LiveAlertEventDetail)} options={{ headerShown: false, title: translate('detection') }} />
            <Stack.Screen name='SearchEventDetail' component={validateSession(SearchEventDetail)} options={{ headerShown: false, title: translate('detection') }} />
            <Stack.Screen name='Facial' options={{ headerShown: false }}>
                {() => (
                    <FacialStack.Navigator initialRouteName='LiveEvents'>
                        <FacialStack.Screen name='LiveEvents' initialParams={{ page: 0 }} component={validateSession(LiveEvents)} options={{ headerShown: false, title: translate('liveEvents') }} />
                        <FacialStack.Screen name='LiveAlerts' component={validateSession(LiveAlerts)} options={{ headerShown: false, title: translate('liveAlerts') }} />
                        <FacialStack.Screen name='Search' component={validateSession(Search)} initialParams={{
                            page: 1,
                            onlyWithPerson: true,
                            endIn: moment().toDate().getTime(),
                        }} options={{ headerShown: false, title: translate('search') }} />
                        <FacialStack.Screen name='Markers' component={validateSession(Markers)} options={{ headerShown: false, title: translate('tags') }} />
                        <FacialStack.Screen name='PeopleList' component={validateSession(PeopleList)} initialParams={{ page: 1, onlyActive: true }} options={{ headerShown: false, title: translate('registerPerson') }} />
                        <FacialStack.Screen name='AllowListGroup' component={validateSession(AllowListGroup)} initialParams={{ page: 1 }} options={{ headerShown: false, title: translate('allowListGroup') }} />
                        <FacialStack.Screen name='PersonForm' component={validateSession(PersonForm)} options={{ headerShown: false, title: translate('registerPerson') }} />
                    </FacialStack.Navigator>
                )}
            </Stack.Screen>
            <Stack.Screen name='SmartSearchFacial' options={{ headerShown: false }}>
                {() => (
                    <SmartSearchFacialStack.Navigator initialRouteName='SmartSearchFacial'>
                        <SmartSearchFacialStack.Screen name='SmartSearchFacial' component={validateSession(SmartSearchFacial)} options={{ headerShown: false, title: translate('smartSearchFacial') }} />
                    </SmartSearchFacialStack.Navigator>
                )}
            </Stack.Screen>
            <Stack.Screen name='User' options={{ headerShown: false }}>
                {() => (
                    <UserStack.Navigator initialRouteName='Account'>
                        <UserStack.Screen name='Account' component={validateSession(Account)} options={{ headerShown: false, title: translate('account') }} />
                        <UserStack.Screen name='Password' component={validateSession(Password)} options={{ headerShown: false, title: translate('changePassword') }} />
                        <UserStack.Screen name='Sessions' component={validateSession(Sessions)} options={{ headerShown: false, title: translate('sessions') }} />
                    </UserStack.Navigator>
                )}
            </Stack.Screen>
            <Stack.Screen name='SearchLprDetection' component={validateSession(SearchLprDetection)} options={{ headerShown: false, title: translate('detection') }} />
            <Stack.Screen name='LiveAlertLprDetection' component={validateSession(LiveAlertLprDetection)} options={{ headerShown: false, title: translate('detection') }} />
            <Stack.Screen name='LiveEventLprDetection' component={validateSession(LiveEventLprDetection)} options={{ headerShown: false, title: translate('detection') }} />
            <Stack.Screen name='MapLprDetection' component={validateSession(MapLprDetection)} options={{ headerShown: false, title: translate('detection') }} />
            <Stack.Screen name='LPR' options={{ headerShown: false }}>
                {() => (
                    <LprStack.Navigator initialRouteName='LprLiveEvents'>
                        <LprStack.Screen name='LprLiveEvents' initialParams={{ page: 0 }} component={validateSession(LprLiveEvents)} options={{ headerShown: false, title: translate('liveEvents') }} />
                        <LprStack.Screen name='LprLiveAlerts' component={validateSession(LprLiveAlerts)} options={{ headerShown: false, title: translate('liveAlerts') }} />
                        <LprStack.Screen name='LprSearch' component={validateSession(LprSearch)} initialParams={{
                            page: 1,
                            endIn: moment().toDate().getTime(),
                        }} options={{ headerShown: false, title: translate('search') }} />
                        <LprStack.Screen name='Plates' component={validateSession(Plates)} initialParams={{ page: 1, onlyActive: true }} options={{ headerShown: false, title: translate('plates') }} />
                        <LprStack.Screen name='LprReports' component={validateSession(LprReports)} initialParams={{ page: 1 }} options={{ headerShown: false, title: translate('reports') }} />
                    </LprStack.Navigator>
                )}
            </Stack.Screen>
            <Stack.Screen name='GuardianApp' options={{ headerShown: false }}>
                {() => (
                    <GuardianAppStack.Navigator initialRouteName='GuardianAppUsers'>
                        <GuardianAppStack.Screen name='GuardianAppUsers' component={validateSession(GuardianAppUsers)} initialParams={{ page: 1 }} options={{ headerShown: false, title: translate('guardianApp') }} />
                    </GuardianAppStack.Navigator>
                )}
            </Stack.Screen>
            <Stack.Screen name='Access' options={{ headerShown: false }}>
                {() => (
                    <AccessStack.Navigator initialRouteName='Users'>
                        <AccessStack.Screen name='Users' component={validateSession(Users)} initialParams={{ page: 1 }} options={{ headerShown: false, title: translate('users') }} />
                        <AccessStack.Screen name='UserTags' component={validateSession(UserTags)} options={{ headerShown: false, title: translate('userTags') }} />
                        <AccessStack.Screen name='PermissionLevels' component={validateSession(PermissionLevels)} initialParams={{ page: 1 }} options={{ headerShown: false, title: translate('permissionLevels') }} />
                        <AccessStack.Screen name='Turnstiles' component={validateSession(Turnstiles)} initialParams={{ page: 1 }} options={{ headerShown: false, title: translate('turnstiles') }} />
                        <AccessStack.Screen name='Groups' component={validateSession(Groups)} initialParams={{ page: 1 }} options={{ headerShown: false, title: translate('groups') }} />
                        <AccessStack.Screen name='ActingBodies' component={validateSession(ActingBodies)} initialParams={{ page: 1 }} options={{ headerShown: false, title: translate('departments') }} />
                        <AccessStack.Screen name='InstallationCompanies' component={validateSession(InstallationCompanies)} initialParams={{ page: 1 }} options={{ headerShown: false, title: translate('installationCompanies') }} />
                        <AccessStack.Screen name='InternetCompanies' component={validateSession(InternetCompanies)} initialParams={{ page: 1 }} options={{ headerShown: false, title: translate('internetCompanies') }} />
                        <AccessStack.Screen name='PlatformSettings' component={validateSession(PlatformSettings)} options={{ headerShown: false, title: translate('platformSettings') }} />
                        <AccessStack.Screen name='PtzPriority' component={validateSession(PtzPriority)} options={{ headerShown: false, title: translate('ptzPriority') }} />
                    </AccessStack.Navigator>
                )}
            </Stack.Screen>
            <Stack.Screen name='Events' options={{ headerShown: false }}>
                {() => (
                    <EventStack.Navigator initialRouteName='EventsBoard'>
                        <EventStack.Screen name='EventsBoard' initialParams={{ beginIn: moment().subtract(12, 'hours').valueOf() }} component={validateSession(EventsBoard)} options={{ headerShown: false, title: translate('events') }} />
                        <EventStack.Screen name='EventsOccurrenceDetail' component={validateSession(EventsOccurrenceDetail)} options={{ headerShown: false, title: translate('occurrences') }} />
                    </EventStack.Navigator>
                )}
            </Stack.Screen>
            <Stack.Screen name='GcmAgent' options={{ headerShown: false }}>
                {() => (
                    <EventStack.Navigator initialRouteName='AgentOccurrences'>
                        <EventStack.Screen name='AgentOccurrences' component={validateSession(AgentOccurrences)} options={{ headerShown: false, title: translate('occurrences') }} />
                        <EventStack.Screen name='AgentOccurrenceDetails' component={validateSession(AgentOccurrenceDetails)} options={{ headerShown: false, title: translate('occurrences') }} />
                        <EventStack.Screen name='PersonOfInterest' component={validateSession(PersonOfInterest)} options={{ headerShown: false, title: translate('personOfInterest') }} />
                        <EventStack.Screen name='AgentUnits' component={validateSession(AgentUnits)} options={{ headerShown: false, title: translate('teams') }} />
                        <EventStack.Screen name='CortexFinder' component={validateSession(CortexFinder)} options={{ headerShown: false, title: 'Cortex' }} />
                    </EventStack.Navigator>
                )}
            </Stack.Screen>
            <Stack.Screen name='Dashboard' options={{ headerShown: false }}>
                {() => (
                    <DashboardStack.Navigator initialRouteName='DashboardOccurrences'>
                        <DashboardStack.Screen name='DashboardCameras' component={validateSession(DashboardCameras)} options={{ headerShown: false, title: translate('dashboard') }} />
                        <DashboardStack.Screen name='DashboardOccurrences' initialParams={{ page: 1, beginIn: moment().subtract(12, 'hours').valueOf() }} component={validateSession(DashboardOccurrences)} options={{ headerShown: false, title: translate('dashboard') }} />
                        <DashboardStack.Screen name='DashboardAgents' initialParams={{ page: 1 }} component={validateSession(DashboardAgents)} options={{ headerShown: false, title: translate('agents') }} />
                    </DashboardStack.Navigator>
                )}
            </Stack.Screen>
            <Stack.Screen name='ManagementDetails' component={validateSession(ManagementDetails)} options={{ headerShown: false, title: translate('cameras') }} />
            <Stack.Screen name='AlarmCenterDetails' component={validateSession(AlarmCenterDetails)} options={{ headerShown: false, title: translate('alarmCenters') }} />
            <Stack.Screen name='CRM' options={{ headerShown: false }}>
                {() => (
                    <CrmStack.Navigator initialRouteName='Management'>
                        <CrmStack.Screen name='CameraHistory' component={validateSession(CameraHistory)} initialParams={{ page: 1 }} options={{ headerShown: false, title: translate('cameraHistory') }} />
                        <CrmStack.Screen name='Management' component={validateSession(Management)} initialParams={{ page: 1 }} options={{ headerShown: false, title: translate('cameras') }} />
                        <CrmStack.Screen name='AlarmCentersList' component={validateSession(AlarmCentersList)} initialParams={{ page: 1 }} options={{ headerShown: false, title: translate('alarmCenters') }} />
                        <CrmStack.Screen name='OfflineEvents' component={validateSession(OfflineEvents)} options={{ headerShown: false, title: translate('offlineEvents') }} />
                        <CrmStack.Screen name='CrmHiddenExport' component={validateSession(Management)} initialParams={{ page: 1 }} options={{ headerShown: false, title: translate('cameras') }} />
                        <CrmStack.Screen name='MaintenanceRequests' component={validateSession(MaintenanceRequests)} options={{ headerShown: false, title: translate('maintenanceRequests') }} />
                    </CrmStack.Navigator>
                )}
            </Stack.Screen>
            <Stack.Screen name='Logs' options={{ headerShown: false }}>
                {() => (
                    <LogsStack.Navigator initialRouteName='LogList'>
                        <LogsStack.Screen name='LogList' component={validateSession(LogList)} initialParams={{ dateLte: moment().endOf('day').valueOf(), dateGte: moment().startOf('day').valueOf() }} options={{ headerShown: false, title: translate('logs') }} />
                        <LogsStack.Screen name='LogReports' component={validateSession(LogReports)} initialParams={{ page: 1 }} options={{ headerShown: false, title: translate('reports') }} />
                    </LogsStack.Navigator>
                )}
            </Stack.Screen>
        </Stack.Navigator >
    );
}
